@use '../includes' as *;

// reset-like styles - derived from https://mattbrictson.com/blog/css-normalize-and-reset
:root {
  font-family: $sans-serif;
  font-size: $font-size-base;
  font-weight: $normal;
  line-height: $line-height;
  scroll-behavior: smooth;

  @media (prefers-reduced-motion: reduce) {
    scroll-behavior: auto;
  }
}

h1,
h2,
h3,
h4,
h5,
figure,
p,
ol,
ul {
  margin: 0;
}

ol,
ul {
  padding-inline: 0;
  list-style: none;
}

h1,
h2,
h3,
h4,
h5 {
  font-size: inherit;
  font-weight: inherit;
}

img {
  display: block;
  height: auto;
  max-inline-size: 100%;
}

body {
  // ensures the page always takes up the full viewport
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: var(--body-background-color);
  color: var(--text-color);

  @include breakpoint($sm) {
    padding-block-start: var(--navigation-with-border-height);
  }
}

main {
  width: 100%;
  max-width: $max-width;
  margin: 0 auto;
  overflow: hidden;
  background-color: var(--content-well-background-color);
}

a {
  &,
  &:visited {
    transition: color var(--duration-half) ease-in;
    color: var(--link-color);
    text-decoration: none;
  }

  svg,
  path {
    transition: fill var(--duration-half) ease-in;
  }

  &:hover,
  &:focus-visible,
  &:active {
    color: var(--link-active-color);
  }

  &:focus-visible {
    outline: $focusstatelight;
  }
}

.visuallyhidden {
  @include visuallyhidden;
}

// responsive utility classes
// using !important so that they override other display values
.visible_below_sm {
  @include breakpoint($sm) {
    display: none !important;
  }
}

.visible_above_sm {
  @include breakpoint($below-sm) {
    display: none !important;
  }
}

// we use this icon all over the place
svg[class*='external-link'] {
  width: clamp(14px, 1.5vw + 0.5em, 19px);
  fill: currentcolor;
}

input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-decoration {
  // stylelint-disable-next-line
  -webkit-appearance: none;
}
