@use '../../components/includes' as *;

.configurable_badge {
  display: flex;
  position: sticky;
  z-index: 5;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-block: $g16;
  padding-inline: var(--spacing-inline);
  animation-name: fade-in;
  animation-duration: 1s;
  animation-delay: 2s;
  animation-iteration-count: 1;
  opacity: 0;
  background-color: $teal;
  animation-fill-mode: forwards;

  @include body-copy-2($navy-blue);

  @include breakpoint($configurable-badge-bp) {
    position: fixed;
    inset: auto var(--spacing-inline) 0 var(--spacing-inline);
    flex-direction: row;
    gap: 4%;
    padding-block: $g4;
    border-radius: 10px 10px 0 0;
  }
}

.teal {
  background-color: $teal;
}

.yellow {
  background-color: $yellow;
}

.red {
  background-color: $red;
}

.quarter {
  @include breakpoint($below-sm) {
    height: 25vh;
  }
}

.half {
  @include breakpoint($below-sm) {
    height: 50vh;
  }
}

.configurable_badge__close_button {
  all: unset;
  position: absolute;
  inset-block-start: 10px;
  inset-inline-end: var(--spacing-inline);
  width: 20px;
  cursor: pointer;

  svg,
  path {
    fill: $navy-blue;
  }

  @include breakpoint($configurable-badge-bp) {
    inset-inline-end: 20px;
  }
}

.configurable_badge__show_logo {
  img {
    width: auto;
    max-width: 200px;
    max-height: 35px;
  }

  @include breakpoint($configurable-badge-bp) {
    img {
      width: auto;
      max-height: 30px;
    }
  }
}

.configurable_badge__headline {
  margin-block-end: $g8;
  text-align: center;

  @include breakpoint($configurable-badge-bp) {
    margin-block-end: 0;
  }
}

a.configurable_badge__cta.configurable_badge__cta {
  display: flex;
  align-items: center;

  @include body-copy-2($pbs-blue);

  font-weight: $bold;

  svg {
    height: 1em;
    margin-inline: $g8;
    fill: $pbs-blue;
  }

  &:hover {
    color: $medium-blue;

    svg {
      fill: $medium-blue;
    }
  }
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
