@use '../includes' as *;

// one-off breakpoint where the illustration needs to anchor to the right
$illustrationbp: 1880px;

.footer__pbsorg {
  // bring the z-index up so the Scroll to top button on shows landing slides under
  z-index: 1;
  margin-block-start: auto;
  margin-block-end: var(--spacing-block);
  background-color: $navy-blue;
  color: $fog;
  font-size: $f12;

  @include breakpoint($sm) {
    font-size: $f14;
  }

  @include breakpoint($sm) {
    font-size: $f16;
  }
}

.footer_inner {
  display: flow-root; // to deal with collapsing margins
  position: relative;
  overflow: hidden; // to clip the illustration
}

.footer_linklists {
  display: grid;
  gap: $g32;
  padding-block-start: $g24;
  padding-inline: var(--spacing-inline);

  @include breakpoint($xs) {
    grid-template-columns: repeat(3, 1fr);
  }

  @include breakpoint($sm) {
    gap: $g64;
    width: 85%;
    padding-block-start: $g64;
  }

  @include breakpoint($md) {
    grid-template-columns: 2.5fr 2.5fr 1fr;
    gap: 60px;
    width: 75%;
    margin-block-end: $g32;
    padding-inline-end: 0;
  }

  @include breakpoint($xl) {
    grid-template-columns: 2.5fr 2fr 1fr;
    max-width: 110rem;
    margin-block-end: 0;
  }

  @include breakpoint($illustrationbp) {
    max-width: calc(100% - 75rem);
  }

  li {
    margin-block-end: $g32;
    break-inside: avoid;
    font-size: $f16;
  }
}

.footer_sociallinks {
  display: flex;
  gap: $g16;

  li {
    margin-block-end: 0;
  }

  a {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 42px;
    height: 42px;
    border: 1px solid $white;
    border-radius: 50%;

    img {
      max-width: 60%;
      max-height: 60%;
    }
  }
}

.footer_section__title {
  margin-block-end: $g32;
  color: $coral;
  font-size: $f16;
  font-weight: $bold;
  text-transform: uppercase;
}

.footer_section__first,
.footer_section__second {
  container-type: inline-size;

  .footer_links {
    @container (min-width: 260px) {
      column-gap: $g16;
      columns: 2 auto;
    }
  }
}

.footer_partnerlinks {
  display: flex;
  align-items: center;
  gap: $g32;

  @include breakpoint($xs) {
    margin-block-end: $g32;
  }

  @include breakpoint($sm) {
    margin-block-end: $g64;
  }
  // this is slightly off from $md because of the columns above
  @include breakpoint(1100px) {
    margin-block-start: -#{$g32};
    margin-block-end: $g32;
  }

  padding-inline: var(--spacing-inline);

  a {
    display: block;

    img {
      width: 100%;
    }
  }

  &__kids {
    width: 42px;

    @include breakpoint($md) {
      width: 48px;
    }

    @include breakpoint($xl) {
      width: 60px;
    }
  }

  &__lm {
    width: 85px;

    @include breakpoint($sm) {
      width: 97px;
    }

    @include breakpoint($md) {
      width: 115px;
    }

    @include breakpoint($xl) {
      width: 144px;
    }
  }

  &__app {
    font-weight: $bold;
  }
}

.footer_illustration {
  position: relative;
  inset-inline-end: -4%;
  width: 108%;
  max-width: unset;
  aspect-ratio: 1.6441 / 1;
  pointer-events: none;

  @include breakpoint($xs) {
    position: absolute;
    inset-block-end: 0;
    inset-inline-end: -40%;
    width: 83%;
  }

  @include breakpoint($sm) {
    width: 73%;
  }

  @include breakpoint($md) {
    width: 63%;
  }

  @include breakpoint($xl) {
    inset-inline-start: 112rem;
    width: 75rem;
  }

  @include breakpoint($illustrationbp) {
    inset-inline: auto 0;
  }
}

.footer_subfooter {
  padding-block: $g16;
  padding-inline: var(--spacing-inline);
  background-color: $midnight;
  font-size: $f12;
  line-height: 1.5;
  text-align: center;

  @include breakpoint($xl) {
    display: flex;
    justify-content: center;
    gap: $g8;
    font-size: $f14;
  }
}

.footer_subfooter_links {
  display: flex;
  justify-content: center;
  gap: $g4;

  @include breakpoint($below-xl) {
    li:first-of-type {
      a {
        border: 0;
      }
    }
  }
}

.footer_subfooter_link {
  padding-inline: $g8;
  border-inline-start: 1px solid $white;
}
